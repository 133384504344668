// add the beginning of your app entry
import 'vite/modulepreload-polyfill'

// import './scss/main.scss'
import { addInitialPluginsAndHydrate } from './utils/conditioner'
import 'swiper/css';
import "plyr-react/plyr.css"
import "react-toggle/style.css"

///////////////////////////////
// Dynamic Lazy Load Modules //
///////////////////////////////
addInitialPluginsAndHydrate(document.documentElement)
console.log('🚀 main.ts: test changes')

window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}
